import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {IUser} from "@/models/user";
import router from "@/router";
import AuthRepository from "@/repositories/auth/AuthRepository";
import VerificationRepository from "@/repositories/admin/verification/VerificationRepository";

export enum Roles {
    ROLE_USER = 'user',
    ROLE_CUSTOMER = 'customer',
    ROLE_MODERATOR = 'moderator',
}

export interface User extends IUser {
    api_token: string;
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
    isAdmin: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();
    isAdmin = this.user?.role === Roles.ROLE_MODERATOR;
    hasVerifications = false;
    verificationsInterval = 0;

    /**
     * Check is admin role
     * @returns boolean
     */
    get isUserAdmin(): boolean {
        return this.isAdmin;
    }

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentication errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    get getHasVerifications(): boolean {
        return this.hasVerifications;
    }

    get getVerificationsInterval(): number {
        return this.verificationsInterval;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.user = user;
        this.isAdmin = user?.role === Roles.ROLE_MODERATOR;
        this.errors = {};

        JwtService.saveToken(user.api_token);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.LOGOUT]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.isAdmin = false;
        this.errors = [];

        if (!JwtService.getToken()) {
            return;
        }
        JwtService.destroyToken();
    }

    @Mutation
    [Mutations.SET_HAS_VERIFICATIONS](hasVerifications) {
        this.hasVerifications = hasVerifications
    }

    @Mutation
    [Mutations.SET_VERIFICATIONS_INTERVAL](interval) {
        this.verificationsInterval = interval
    }

    @Action
    [Actions.LOGIN](credentials) {
        return new Promise((resolve) => {
            AuthRepository.login(credentials)
                .then(({data}) => {
                    if(data.otp_required) {
                        resolve(data);
                        return;
                    }
                    this.context.commit(Mutations.SET_AUTH, {...data.user, api_token: data.api_token});
                    this.context.dispatch(Actions.CHECK_VERIFICATIONS)
                    resolve(data);
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors ?? {messages: ['Something went wrong']});
                    resolve({
                        err: response
                    })
                });
        })
    }

    @Action
    async [Actions.LOGOUT]({force = false, goToLoginPage = false} = {}) {
        if(!force) {
            await AuthRepository.logout()
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors ?? {messages: ['Something went wrong']});
                });
        }

        this.context.commit(Mutations.LOGOUT);
        window.clearInterval(this.context.getters.getVerificationsInterval);
        this.context.commit(Mutations.SET_VERIFICATIONS_INTERVAL, 0);

        if(goToLoginPage) router.push({name: 'login'})
    }

    @Action
    [Actions.REGISTER](credentials) {
        return AuthRepository.register( credentials)
            .then(({data}) => {
                this.context.commit(Mutations.SET_AUTH, {...data.user, api_token: data.api_token});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors ?? {messages: ['Something went wrong']});
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return AuthRepository.forgotPassword(payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors ?? {messages: ['Something went wrong']});
            });
    }

    @Action
    async [Actions.VERIFY_AUTH](payload = {api_token: JwtService.getToken()}) {
        let user = null;

        if (JwtService.getToken()) {
            ApiService.setHeader();
            user = await AuthRepository.verifyToken(payload)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_AUTH, {...data.user, api_token: data.api_token});
                    this.context.dispatch(Actions.CHECK_VERIFICATIONS)
                    return data;
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors ?? {messages: ['Something went wrong']});
                    this.context.commit(Mutations.LOGOUT);
                });
        } else {
            this.context.commit(Mutations.LOGOUT);
        }

        return user;
    }

    @Action
    async [Actions.UPDATE_USER]() {
        return  AuthRepository.verifyToken({api_token: JwtService.getToken()})
            .then(({data}) => {
                this.context.commit(Mutations.SET_USER, {...data.user});
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors ?? {messages: ['Something went wrong']});
                this.context.commit(Mutations.LOGOUT);
            });
    }

    @Action
    [Actions.CHECK_VERIFICATIONS]() {
        if(this.context.getters.currentUser?.role !== Roles.ROLE_MODERATOR) return false;
        const check = () => {
            VerificationRepository.check()
                .then(({data}) => {
                    this.context.commit(Mutations.SET_HAS_VERIFICATIONS, data.hasVerifications)
                }).catch(err => {
                console.log(err.response)
            });
        }
        if(this.context.getters.getVerificationsInterval) {
            window.clearInterval(this.verificationsInterval)
        }else check();

        this.context.commit(Mutations.SET_VERIFICATIONS_INTERVAL, window.setInterval(() => {
            check()
        }, 10 * 1000));

    }
}
