import {RouteRecordRaw} from "vue-router";
import {RoutePaths, RouteNames} from "@/router/enum-route";
import {auth, isCustomer} from "@/router/middlewares";
import {acceptedTerms} from "@/router/middlewares/acceptedTerms";

const routes: Array<RouteRecordRaw> = [
    {
        path: RoutePaths.tenant.ROOT,
        name: RouteNames.tenant.ROOT,
        redirect: RoutePaths.tenant.DASHBOARD,
        meta: {middleware: [auth, acceptedTerms, isCustomer]},
        component: () => import("@/layout/Layout.vue"),
        children: [
            {
                path: RoutePaths.tenant.DASHBOARD,
                name: RouteNames.tenant.DASHBOARD,
                component: () => import("@/views/tenant/dashboard/Dashboard.vue"),
            },
            {
                path: RoutePaths.tenant.PROFILE,
                redirect: RoutePaths.tenant.PROFILE_SETTINGS,
                children: [
                    {
                        path: RoutePaths.tenant.PROFILE_SETTINGS,
                        name: RouteNames.tenant.PROFILE_SETTINGS,
                        component: () => import("@/views/tenant/profile/ProfileSettings.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.tenant.SPOTS,
                redirect: RoutePaths.tenant.SPOTS_LIST,
                name: RouteNames.tenant.SPOTS,
                children: [
                    {
                        path: RoutePaths.tenant.SPOTS_LIST,
                        name: RouteNames.tenant.SPOTS_LIST,
                        component: () => import("@/views/tenant/spots/SpotList.vue"),
                    },
                    {
                        path: RoutePaths.tenant.SPOTS_ITEM,
                        name: RouteNames.tenant.SPOTS_ITEM,
                        component: () => import("@/views/tenant/spots/spot-view-item/SpotItem.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.tenant.USERS,
                redirect: RoutePaths.tenant.USERS_LIST,
                name: RouteNames.tenant.USERS,
                children: [
                    {
                        path: RoutePaths.tenant.USERS_LIST,
                        name: RouteNames.tenant.USERS_LIST,
                        component: () => import("@/views/tenant/users/UserList.vue"),
                    },
                    {
                        path: RoutePaths.tenant.USERS_CREATE,
                        name: RouteNames.tenant.USERS_CREATE,
                        component: () => import("@/views/tenant/users/UserCreate.vue"),
                    }
                ],
            },
            {
                path: RoutePaths.tenant.COMPANY,
                redirect: RoutePaths.tenant.COMPANY_SETTINGS,
                name: RouteNames.tenant.COMPANY,
                children: [
                    {
                        path: RoutePaths.tenant.COMPANY_SETTINGS,
                        name: RouteNames.tenant.COMPANY_SETTINGS,
                        component: () => import("@/views/tenant/company/CompanySettings.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.tenant.COMPANIES,
                redirect: RoutePaths.tenant.COMPANIES_LIST,
                name: RouteNames.tenant.COMPANIES,
                children: [
                    {
                        path: RoutePaths.tenant.COMPANIES_LIST,
                        name: RouteNames.tenant.COMPANIES_LIST,
                        component: () => import("@/views/tenant/companies/CompanyList.vue"),
                    },
                    {
                        path: RoutePaths.tenant.COMPANIES_ITEM,
                        name: RouteNames.tenant.COMPANIES_ITEM,
                        component: () => import("@/views/tenant/companies/company-view-item/CompanyItem.vue"),
                    },
                    {
                        path: RoutePaths.tenant.COMPANIES_CREATE,
                        name: RouteNames.tenant.COMPANIES_CREATE,
                        component: () => import("@/views/tenant/companies/CompanyCreate.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.tenant.VERIFICATIONS,
                redirect: RoutePaths.tenant.VERIFICATIONS_LIST,
                name: RouteNames.tenant.VERIFICATIONS,
                children: [
                    {
                        path: RoutePaths.tenant.VERIFICATIONS_LIST,
                        name: RouteNames.tenant.VERIFICATIONS_LIST,
                        component: () => import("@/views/tenant/verifications/VerificationList.vue"),
                    }
                ],
            },
            {
                path: RoutePaths.tenant.TERMS,
                name: RouteNames.tenant.TERMS,
                component: () => import("@/views/tenant/terms/CompanyTerms.vue")
            }
        ],
    }
];

export default routes;