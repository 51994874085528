import ApiService from "@/core/services/ApiService";
import {AxiosPromise} from "axios";

enum Paths {
    LOGIN = "/login",
    LOGOUT = "/logout",
    REGISTER = "/register",
    FORGOT_PASSWORD = "/forgot-password",
    VERIFY_TOKEN = "/verify-token"
}

class AuthRepository {

    public login(credentials): AxiosPromise {
        return ApiService.post(Paths.LOGIN, credentials)
    }

    public logout(): AxiosPromise {
        return ApiService.post(Paths.LOGOUT, {})
    }

    public register(credentials): AxiosPromise {
        return ApiService.post(Paths.REGISTER, credentials)
    }

    public forgotPassword(payload): AxiosPromise {
        return ApiService.post(Paths.FORGOT_PASSWORD, payload)
    }

    public verifyToken(payload): AxiosPromise {
        return ApiService.post(Paths.VERIFY_TOKEN, payload)
    }
}

export default new AuthRepository();