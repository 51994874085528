enum ADMIN_ROUTE_PATHS {
    ROOT = "/admin",
    DASHBOARD = "/admin/dashboard",
    PROFILE_SETTINGS = "/admin/profile/settings",
    // spots
    SPOTS = "/admin/spots",
    SPOTS_LIST = "/admin/spots/list",
    SPOTS_CREATE = "/admin/spots/create",
    SPOTS_ITEM = "/admin/spots/:id/details",
    // users
    USERS = "/admin/users",
    USERS_LIST = "/admin/users/list",
    USERS_CREATE = "/admin/users/create",
    USERS_EDIT = "/admin/users/:id/edit",
    //companies
    COMPANIES = "/admin/companies",
    COMPANIES_LIST = "/admin/companies/list",
    COMPANIES_CREATE = "/admin/companies/create",
    COMPANIES_ITEM = "/admin/companies/:id/details",
    // versions
    VERSIONS = "/admin/versions",
    VERSIONS_LIST = "/admin/versions/list",
    VERSIONS_CREATE = "/admin/versions/create",
    // verifications
    VERIFICATIONS = "/admin/verifications",
    VERIFICATIONS_LIST = "/admin/verifications/list",
    VERIFICATIONS_ITEM = "/admin/verifications/:id",
    VERIFICATIONS_ITEM_EDIT = "/admin/verifications/:id/edit",
    //Terms
    GLOBAL_TERMS = "/admin/terms",
    TERMS_TEMPLATE = "/admin/terms/template",
    // product types
    PRODUCT_TYPES = "/admin/product-types"
}

enum ADMIN_ROUTE_NAMES {
    ROOT = "admin-root",
    DASHBOARD = "admin-dashboard",
    PROFILE = "admin-profile",
    PROFILE_SETTINGS = "admin-profile-settings",
    // spots
    SPOTS = "admin-spots",
    SPOTS_LIST = "admin-spots-list",
    SPOTS_CREATE = "admin-spots-create",
    SPOTS_ITEM = "admin-spots-item",
    // users
    USERS = "admin-users",
    USERS_LIST = "admin-users-list",
    USERS_CREATE = "admin-users-create",
    USERS_EDIT = "admin-users-edit",
    // companies
    COMPANIES = "admin-companies",
    COMPANIES_LIST = "admin-companies-list",
    COMPANIES_CREATE = "admin-companies-create",
    COMPANIES_ITEM = "admin-companies-item",
    // versions
    VERSIONS = "admin-versions",
    VERSIONS_LIST = "admin-versions-list",
    VERSIONS_CREATE = "admin-versions-create",
    // verifications
    VERIFICATIONS = "admin-verifications",
    VERIFICATIONS_LIST = "admin-verifications-list",
    VERIFICATIONS_ITEM = "admin-verifications-item",
    VERIFICATIONS_ITEM_EDIT = "admin-verifications-edit",
    // terms
    GLOBAL_TERMS = "global-terms",
    TERMS_TEMPLATE = "terms-template",
    // product types
    PRODUCT_TYPES = "product-types"
}

enum TENANT_ROUTE_PATHS {
    ROOT = "/",
    DASHBOARD = "/dashboard",
    PROFILE = "/profile",
    PROFILE_SETTINGS = "/profile/settings",
    // spots
    SPOTS = "/spots",
    SPOTS_LIST = "/spots/list",
    SPOTS_ITEM = "/spots/:id/details",
    // users
    USERS = "/users",
    USERS_LIST = "/users/list",
    USERS_CREATE = "/users/create",
    // company
    COMPANY = "/company",
    COMPANY_SETTINGS = "/company/settings",
    // companies
    COMPANIES = "/companies",
    COMPANIES_LIST = "/companies/list",
    COMPANIES_ITEM = "/companies/:id/details",
    COMPANIES_CREATE = "/companies/create",
    //verifications
    VERIFICATIONS = "/verifications",
    VERIFICATIONS_LIST = "/verifications/list",
    //Terms
    TERMS = "/terms",
    ACCEPT_TERMS = "/accept-terms"
}

enum TENANT_ROUTE_NAMES {
    ROOT = "tenant-root",
    DASHBOARD = "tenant-dashboard",
    PROFILE = "tenant-profile",
    PROFILE_SETTINGS = "tenant-profile-settings",
    // spots
    SPOTS = "tenant-spots",
    SPOTS_LIST = "tenant-spots-list",
    SPOTS_ITEM = "tenant-spots-item",
    // users
    USERS = "tenant-users",
    USERS_LIST = "tenant-users-list",
    USERS_CREATE = "tenant-users-create",
    // company
    COMPANY = "tenant-company",
    COMPANY_SETTINGS = "tenant-company-settings",
    // companies
    COMPANIES = "tenant-companies",
    COMPANIES_LIST = "tenant-companies-list",
    COMPANIES_ITEM = "tenant-companies-item",
    COMPANIES_CREATE = "tenant-companies-create",
    // verifications
    VERIFICATIONS = "tenant-verifications",
    VERIFICATIONS_LIST = "tenant-verifications-list",
    // terms
    TERMS = "terms",
    ACCEPT_TERMS = "accept-terms"
}


const RoutePaths = {
    admin: ADMIN_ROUTE_PATHS,
    tenant: TENANT_ROUTE_PATHS
};
const RouteNames = {
    admin: ADMIN_ROUTE_NAMES,
    tenant: TENANT_ROUTE_NAMES
}

export {
    RoutePaths,
    RouteNames,
};
