import store from "@/store";
import {Roles} from "@/store/modules/AuthModule";
import router from "@/router";
import {RouteNames} from "@/router/enum-route";

const acceptedTerms = async ({next}) => {
    const user = store.getters.currentUser;
    if(user.role === Roles.ROLE_MODERATOR) return next;

    if(user.company.need_to_accept_terms && !user.company.accepted_terms) {
        await router.push({ name: RouteNames.tenant.ACCEPT_TERMS })
        return;
    }

    return next
}

export {acceptedTerms}