const makeFormData = (
    values: { [key: number | string]: string | Blob },
    withEmptyFields = false
) => {
    const formData = new FormData();
    for (const name in values) {
        let field = values[name];

        if (!withEmptyFields && field === undefined) {
            continue;
        }

        if (typeof field === 'object' && !(field instanceof File)) {
            field = JSON.stringify(field)
        }

        formData.append(name, field);
    }

    return formData;
}

const filterParams = (params = {}) => {
    if(!Object.keys(params).length) return params;

    params = {...params};
    const valuesToExclude = [null, '', 'All', undefined];

    for(const key in params) {
        if(valuesToExclude.includes(params[key])) {
            delete params[key]
        }
    }
    return params
}

export {makeFormData, filterParams};
