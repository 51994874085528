import store from "@/store";
import router from "@/router";
import JwtService from "@/core/services/JwtService";
import {userRouteNames} from "@/router/helpers/routeHelper";

const guest = async ({next}) => {
    const isAuthenticated = store.getters.isUserAuthenticated;

    if (isAuthenticated && JwtService.getToken()) {
        await router.push({name: userRouteNames().DASHBOARD});
        return next
    }
    return next;
}

export {guest}