import {App} from "vue";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL = "/";
        ApiService.addAjaxHeader();
        ApiService.vueInstance.axios.interceptors.response.use(response => response, async (error) => {
            if(error.response && error.response.status === 401) {
                if(error.response.config.url === '/verify-token') {
                    await store.dispatch(Actions.LOGOUT, {force: true, goToLoginPage: true});
                }else {
                    await store.dispatch(Actions.VERIFY_AUTH);
                }
            }
            return Promise.reject(error);
        })
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${JwtService.getToken()}`;
        ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
            "application/json";
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(resource, params);
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get(
        resource: string,
        slug = "" as string
    ): Promise<AxiosResponse> {
        const url = slug.length ? `${resource}/${slug}` : resource

        return ApiService.vueInstance.axios.get(url);
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param data
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(
        resource: string,
        data?: FormData | Record<string, string | number | null>,
        params?: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.post(`${resource}`, data, params);
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param data
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update(
        resource: string,
        slug: string,
        data?: FormData | Record<string, string | number | null>,
        params?: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        const url = slug.length ? `${resource}/${slug}` : resource

        return ApiService.vueInstance.axios.put(url, data, params);
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param data
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(
        resource: string,
        data?: FormData | Record<string, string | number | null>,
        params?: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        if(data instanceof FormData) data.append('_method', 'PUT');
        else if (data) data['_method'] = 'PUT';
        return ApiService.vueInstance.axios.post(`${resource}`, data, params);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.delete(resource);
    }

    public static addAjaxHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    }

    public static addCSRF(): void {
        const el = document.querySelector('meta[name="csrf-token"]');

        ApiService.vueInstance.axios.defaults.withCredentials = true;
        ApiService.vueInstance.axios.defaults.headers.common['X-CSRF-TOKEN'] = el ? el.getAttribute('content') : null;
    }
}

export default ApiService;
