import {RouteRecordRaw} from "vue-router";
import {RoutePaths, RouteNames} from "@/router/enum-route";
import {auth, isAdmin} from "@/router/middlewares";

const routes: Array<RouteRecordRaw> = [
    {
        path: RoutePaths.admin.ROOT,
        name: RouteNames.admin.ROOT,
        redirect: RoutePaths.admin.DASHBOARD,
        component: () => import("@/layout/Layout.vue"),
        meta: {middleware: [auth, isAdmin]},
        children: [
            {
                path: RoutePaths.admin.DASHBOARD,
                name: RouteNames.admin.DASHBOARD,
                component: () => import("@/views/admin/dashboard/Dashboard.vue"),
            },
            {
                path: RoutePaths.admin.PROFILE_SETTINGS,
                name: RouteNames.admin.PROFILE_SETTINGS,
                component: () => import("@/views/admin/profile/ProfileSettings.vue"),
            },
            {
                path: RoutePaths.admin.SPOTS,
                redirect: RoutePaths.admin.SPOTS_LIST,
                name: RouteNames.admin.SPOTS,
                children: [
                    {
                        path: RoutePaths.admin.SPOTS_LIST,
                        name: RouteNames.admin.SPOTS_LIST,
                        component: () => import("@/views/admin/spots/SpotList.vue"),
                    },
                    {
                        path: RoutePaths.admin.SPOTS_CREATE,
                        name: RouteNames.admin.SPOTS_CREATE,
                        component: () => import("@/views/admin/spots/SpotCreate.vue"),
                    },
                    {
                        path: RoutePaths.admin.SPOTS_ITEM,
                        name: RouteNames.admin.SPOTS_ITEM,
                        component: () => import("@/views/admin/spots/spot-view-item/SpotItem.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.admin.USERS,
                redirect: RoutePaths.admin.USERS_LIST,
                name: RouteNames.admin.USERS,
                children: [
                    {
                        path: RoutePaths.admin.USERS_LIST,
                        name: RouteNames.admin.USERS_LIST,
                        component: () => import("@/views/admin/users/UserList.vue"),
                    },
                    {
                        path: RoutePaths.admin.USERS_CREATE,
                        name: RouteNames.admin.USERS_CREATE,
                        component: () => import("@/views/admin/users/UserCreate.vue"),
                    },
                    {
                        path: RoutePaths.admin.USERS_EDIT,
                        name: RouteNames.admin.USERS_EDIT,
                        component: () => import("@/views/admin/users/UserEdit.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.admin.COMPANIES,
                redirect: RoutePaths.admin.COMPANIES_LIST,
                name: RouteNames.admin.COMPANIES,
                children: [
                    {
                        path: RoutePaths.admin.COMPANIES_LIST,
                        name: RouteNames.admin.COMPANIES_LIST,
                        component: () => import("@/views/admin/companies/CompanyList.vue"),
                    },
                    {
                        path: RoutePaths.admin.COMPANIES_CREATE,
                        name: RouteNames.admin.COMPANIES_CREATE,
                        component: () => import("@/views/admin/companies/CompanyCreate.vue"),
                    },
                    {
                        path: RoutePaths.admin.COMPANIES_ITEM,
                        name: RouteNames.admin.COMPANIES_ITEM,
                        component: () => import("@/views/admin/companies/company-view-item/CompanyItem.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.admin.VERSIONS,
                redirect: RoutePaths.admin.VERSIONS_LIST,
                name: RouteNames.admin.VERSIONS,
                children: [
                    {
                        path: RoutePaths.admin.VERSIONS_LIST,
                        name: RouteNames.admin.VERSIONS_LIST,
                        component: () => import("@/views/admin/versions/VersionList.vue"),
                    },
                    {
                        path: RoutePaths.admin.VERSIONS_CREATE,
                        name: RouteNames.admin.VERSIONS_CREATE,
                        component: () => import("@/views/admin/versions/VersionCreate.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.admin.VERIFICATIONS,
                redirect: RoutePaths.admin.VERIFICATIONS_LIST,
                name: RouteNames.admin.VERIFICATIONS,
                children: [
                    {
                        path: RoutePaths.admin.VERIFICATIONS_LIST,
                        name: RouteNames.admin.VERIFICATIONS_LIST,
                        component: () => import("@/views/admin/verifications/VerificationList.vue"),
                    },
                    {
                        path: RoutePaths.admin.VERIFICATIONS_ITEM,
                        name: RouteNames.admin.VERIFICATIONS_ITEM,
                        component: () => import("@/views/admin/verifications/VerificationItem.vue"),
                    },
                    {
                        path: RoutePaths.admin.VERIFICATIONS_ITEM_EDIT,
                        name: RouteNames.admin.VERIFICATIONS_ITEM_EDIT,
                        component: () => import("@/views/admin/verifications/VerificationEdit.vue"),
                    },
                ],
            },
            {
                path: RoutePaths.admin.PRODUCT_TYPES,
                name: RouteNames.admin.PRODUCT_TYPES,
                component: () => import('@/views/admin/productTypes/ProductTyeps.vue')
            },
            {
                path: RoutePaths.admin.GLOBAL_TERMS,
                name: RouteNames.admin.GLOBAL_TERMS,
                component: () => import('@/views/admin/terms/global/GlobalTerms.vue')
            },
            {
                path: RoutePaths.admin.TERMS_TEMPLATE,
                name: RouteNames.admin.TERMS_TEMPLATE,
                component: () => import('@/views/admin/terms/template/TermsTemplate.vue'),
            }
        ],
    },
];

export default routes;
