import JwtService from "@/core/services/JwtService";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import router from "@/router";

const auth = async ({next}) => {
    const isAuthenticated = store.getters.isUserAuthenticated;
    const user = store.getters.currentUser;

    if (!isAuthenticated || !user.api_token || !JwtService.getToken()) {
        const result = await store.dispatch(Actions.VERIFY_AUTH, {api_token: JwtService.getToken()});
        if(!result) {
            await router.push({name: 'login'});
            return next;
        }
    }
    return next;
}

export {auth}