import {RouteRecordRaw} from "vue-router";
import {guest} from "@/router/middlewares";
import {RouteNames, RoutePaths} from "@/router/enum-route";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () => import("@/components/page-layouts/Auth.vue"),
        meta: {middleware: guest},
        redirect: '/login',
        children: [
            {
                path: "/login",
                name: "login",
                component: () => import("@/views/crafted/authentication/basic-flow/Login.vue"),
            }
        ],
    },
    {
        path: RoutePaths.tenant.ACCEPT_TERMS,
        name: RouteNames.tenant.ACCEPT_TERMS,
        component: () => import("@/views/tenant/terms/AcceptTerms.vue")
    }
];

export default routes;
