import ApiService from "@/core/services/ApiService";
import {AxiosPromise} from "axios";
import {makeFormData} from "@/core/helpers/form";
import moment from "moment";

enum Paths {
    API_VERIFICATIONS_LIST = "/verifications",
    API_VERIFICATIONS_ITEM = "/verifications/:id",
    API_VERIFICATIONS_CREATE = "/verifications",
    API_VERIFICATIONS_EDIT = "/verifications/:id",
    API_VERIFICATIONS_DELETE = "/verifications/:id",
    API_VERIFICATIONS_UPDATE_STATUS = "/verifications/:id/:status",
    API_VERIFICATIONS_CHECK = "/verifications/check",
    API_VERIFICATIONS_IMAGE = "/verifications/image",
    API_VERIFICATIONS_DECRYPT = "/verifications/decrypt",
    API_VERIFICATIONS_LAST = "/verifications/last",
    API_GENERATE_FORCE_VERIFICATION = "/verifications/generateForcedVerification/:spotId",
    API_VERIFICATIONS_FILTERS = "/verifications/filters"
}

class VerificationRepository {

    public index({page = 1, perPage = 10, filter = {}} = {}): AxiosPromise {
        return ApiService.query(Paths.API_VERIFICATIONS_LIST, {
            params: {
                page,
                per_page: perPage,
                ...filter,
            }
        })
    }

    public show(id: string | string[]): AxiosPromise {
        return ApiService.get(Paths.API_VERIFICATIONS_ITEM.replace(':id', `${id}`))
    }

    public create(data): AxiosPromise {
        return ApiService.post(Paths.API_VERIFICATIONS_CREATE, makeFormData(data));
    }

    public generateForce(spotId: string | string[]): AxiosPromise {
        return ApiService.post(Paths.API_GENERATE_FORCE_VERIFICATION.replace(':spotId', `${spotId}`));
    }

    public update(id: string | string[], data: Record<string, string | Blob>): AxiosPromise {
        return ApiService.put(Paths.API_VERIFICATIONS_EDIT.replace(':id', `${id}`), makeFormData(data))
    }

    public delete(id: string | string[]): AxiosPromise {
        return ApiService.delete(Paths.API_VERIFICATIONS_DELETE.replace(':id', `${id}`))
    }

    public updateStatus(id: string | string[], status: number): AxiosPromise {
        return ApiService.put(Paths.API_VERIFICATIONS_UPDATE_STATUS
            .replace(':id', `${id}`)
            .replace(':status', `${status}`))
    }

    public check(): AxiosPromise {
        return ApiService.get(Paths.API_VERIFICATIONS_CHECK);
    }

    public lastVerifications() {
        return ApiService.get(Paths.API_VERIFICATIONS_LAST)
    }

    public getVerificationsImagePath(path: string) {
        const url = Paths.API_VERIFICATIONS_IMAGE;
        const time = moment.now();
        path = encodeURIComponent(path);

        return `${url}?path=${path}&time=${time}`;
    }

    public getVerificationsDecrypt(path: string) {
        const url = Paths.API_VERIFICATIONS_DECRYPT;
        const time = moment.now();
        path = encodeURIComponent(path);

        return `${url}?path=${path}&time=${time}`;
    }

    public getFilters($companyId?: number | null): AxiosPromise {
        if($companyId) {
            return ApiService.query(Paths.API_VERIFICATIONS_FILTERS, {
                params: {
                    company_id: $companyId
                }
            })
        }
        return ApiService.get(Paths.API_VERIFICATIONS_FILTERS)
    }

}

export default new VerificationRepository();