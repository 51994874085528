import {createRouter, createWebHashHistory, RouteRecordRaw} from "vue-router";

import statuses from "./statuses";
import auth from "./auth";
import admin from "./admin";
import tenant from "./tenant";

const routes: Array<RouteRecordRaw> = [];

routes.push(...auth);
routes.push(...admin);
routes.push(...tenant);
routes.push(...statuses);

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}


router.beforeEach(async (to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {from, next, router, to};
        const nextMiddleware = nextFactory(context, middleware, 1);
        const _next = await middleware[0]({...context, next: nextMiddleware})
        return _next();
    }
    return next()
});

export default router;
