import {RoutePaths, RouteNames} from "@/router/enum-route";
import store from "@/store";
import {Roles} from "@/store/modules/AuthModule";

const userRoutePaths = () => {
    const user = store.getters.currentUser
    return RoutePaths[user.role === Roles.ROLE_MODERATOR ? 'admin' : 'tenant']
}

const userRouteNames = () => {
    const user = store.getters.currentUser
    return RouteNames[user.role === Roles.ROLE_MODERATOR ? 'admin' : 'tenant']
}

export {userRouteNames, userRoutePaths}
