
    import {defineComponent, nextTick, onMounted} from "vue";
    import {useStore} from "vuex";
    import {Mutations} from "@/store/enums/StoreEnums";
    import {initializeComponents} from "@/core/plugins/keenthemes";
    import {onBeforeMount} from "@vue/runtime-core";
    import ApiService from "@/core/services/ApiService";

    export default defineComponent({
        name: "app",
        setup() {
            const store = useStore();
            onBeforeMount(() => {
                let isAuthenticated = store.getters.isUserAuthenticated;
                if(isAuthenticated) {
                    ApiService.setHeader();
                }
            })
            onMounted(() => {
                /**
                 * this is to override the layout config using saved data from localStorage
                 * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
                 */
                store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

                nextTick(() => initializeComponents());
            });
        },
    });
