import store from "@/store";
import {Roles} from "@/store/modules/AuthModule";
import router from "@/router";

const isCustomer = async ({next}) => {
    const user = store.getters.currentUser;
    if(user.role !== Roles.ROLE_CUSTOMER) {
        await router.push({name: 'login'});
        return;
    }
    return next;
}

export {isCustomer}